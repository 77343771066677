import { loadableReady } from "@loadable/component"
import React from "react"
// eslint-disable-next-line react/no-deprecated
import { hydrate } from "react-dom"

// eslint-disable-next-line no-unused-vars
export const withClientRenderer = (Component) => (
  props,
  _railsContext,
  domId
) => {
  loadableReady(() => {
    const root = document.getElementById(domId)
    hydrate(<Component {...props} />, root)
  })
}
